$screen-sm-max: 640px;
$screen-md-min: 767px;
$screen-lg-min: 991px;

@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
