$white: #fff;
$translucentWhite: rgba(255, 255, 255, 0.2);
$whiteBackground: rgba(0, 0, 0, 0.7);
$logoBg: #304567;
$blueBorder: #72e9f1;
$lightBlueBorder: #72daf1;
$translucentBlack: rgba(0, 0, 0, 0.8);
$linkColor: #5370ff;
$halfBlack: rgba(0, 0, 0, 0.5);
$darkBlack: rgba(0, 0, 0, 0.9);
$lightBlack: rgba(0, 0, 0, 0.6);
$darkBlueHover: #6b62d0;
$darkBlue: #4a3ec6;
$disabledBlueBg: rgba(69, 99, 250, 0.5);
$gradientBlueBorder: #72e2f1;
$redAlert: #de5a48;
$errorBackground: rgba(88, 39, 39, 0.8);
$borderBlack: rgba(0, 0, 0, 0.1);
$inputFieldBlack: rgba(0, 0, 0, 0.2);
$userInputText: rgba(0, 0, 0, 0.8);
$black60: rgba(0, 0, 0, 0.6);
$black70: rgba(0, 0, 0, 0.7);
$black30: rgba(0, 0, 0, 0.3);
$black40: rgba(0, 0, 0, 0.4);
$black: #000;
$white70: rgba(255, 255, 255, 0.7);
$white30: rgba(255, 255, 255, 0.3);
$white80: rgba(255, 255, 255, 0.8);
$white90: rgba(255, 255, 255, 0.9);
$white40: rgba(255, 255, 255, 0.4);
$white50: rgba(255, 255, 255, 0.5);
$white60: rgba(255, 255, 255, 0.6);
$lightBlackBorder: rgba(0, 0, 0, 0.05);
$skeletonBg: #f0f0f0;
$chatColor: rgba(48, 69, 103, 0.5);
$borderWhite: rgba(255, 255, 255, 0.1);
$splashBgColor: #493ab6;
$greyBg: #d9d9d9;
$borderBlue: #0397eb;
$borderRed: #f33621;
$borderGreen: #398b4d;
$gradientBlue: #0ea4c5;
$gradientPurple: #7b4cff;
$buttonColor: #2657a0;
$taglineColor: #ffc32a;
$cardGradientpurple: #9c79ff;
$cardGradientBlue: #0fd4ff;
$tabGradient1: rgba(114, 233, 241, 0.1);
$tabGradient2: rgba(113, 146, 229, 0.1);
$tabGradient3: rgba(98, 87, 215, 0.1);
$greyHover: #ebebeb;
$stepColorActive: rgba(69, 99, 250, 0.5);
$stepColorCompleted: rgba(69, 99, 250, 0.9);
$stepColor: rgba(69, 99, 250, 0.1);
$stepBorder: rgba(69, 99, 250, 0.2);
$tabBg: #e7ebff;
$charBtnBorder: #3e6ec6;
$audioBorder: rgba(69, 99, 250, 0);
$purpleGradientBorder: #6257d7;
$blueGradientBorder: #71a0e5;
$blueGradientBtn: #643dff;
$blueLoader: #95ecff;
$blueGradienatLoader: #60e2ff;
$strokeGrey: #ddd;
$mediaBgColor: rgba(74, 62, 198, 0.1);
$mediaBgColor2: rgba(74, 62, 198, 0.2);
$lightGreyBg: #edecf9;
$lightWhiteBorder: rgba(255, 255, 255, 0.05);
$purpleBackground: #4c4789;
$bodyBackground: radial-gradient(
    28.18% 45% at 0% 58.82%,
    rgba(103, 94, 255, 0.1) 0%,
    rgba(103, 94, 255, 0) 100%
  ),
  radial-gradient(
    30.99% 45.35% at 100% 31.43%,
    rgba(255, 89, 236, 0.1) 0%,
    rgba(255, 89, 236, 0) 100%
  ),
  radial-gradient(
    28.11% 34.78% at 0% 0%,
    rgba(255, 189, 78, 0.1) 0%,
    rgba(255, 189, 78, 0) 100%
  ),
  linear-gradient(0deg, #171717 0%, #171717 100%), #000;
$blueAscent: #988efc;
$loaderBackground: #161616;
$chatBtnBg: #393535;
